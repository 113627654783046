import Vue from "vue";
let request = Vue.prototype.$axios

// 四名活动列表
export function materialArticle(params) {
    return request({
        url: 'index/weChat/materialArticle/lists',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function add_materialArticle(params) {
    return request({
        url: 'index/weChat/materialArticle/add',
        method: 'post',
        data: params,
    })
};

// 四名活动列表
export function det_materialArticle(params) {
    return request({
        url: 'index/weChat/materialArticle/detail',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function copyFamousList(params) {
    return request({
        url: 'index/weChat/materialArticle/copyTeacherArticleList',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function copyFamousDetail(params) {
    return request({
        url: 'index/weChat/materialArticle/copyArticleDetail',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function pushAccountList(params) {
    return request({
        url: 'index/weChat/materialArticle/pushAccountList',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function pushRecordList(params) {
    return request({
        url: 'index/weChat/materialArticle/pushRecordList',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function accountQuotaList(params) {
    return request({
        url: 'index/weChat/materialArticle/accountQuotaList',
        method: 'get',
        params,
    })
};

//发布/取消发布
export function goPush(params) {
    return request({
        url: 'index/weChat/materialArticle/goPush',
        method: 'post',
        data: params
    })
}

// 文章模板列表
export function articleTemplate(params) {
    return request({
        url: 'TeachingStudioArticleType/articleTemplate',
        method: 'get',
        params,
    })
};

// 删除回复
export function delGroup(params) {
    return request({
        url: 'index/weChat/materialArticle/delGroup',
        method: 'delete',
        params,
    })
};