<template>
  <!-- //发布教师文章 -->
  <div class="pushBox">
    <div class="w_layout ArrBox" :class="routeName == 'AddArticle' ? 'w_layout' : ''">
      <!-- 导航 -->
      <div class="title" v-if="routeName == 'AddArticle'">
        <p>
          当前位置：四名工作室 > 教师文章 >
          <span>{{ this.$route.query.id ? '修改' : '发布' }}文章</span>
        </p>
      </div>
      <div class="BoxItem">
        <h4>{{ this.$route.query.id ? '修改' : '发布' }}文章</h4>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="标题" prop="name">
            <el-input v-model="form.name" style="width: 260px"></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="type">
            <el-select v-model="form.type" placeholder="请选择" class="sid" style="width: 260px">
              <template v-for="item in typeList">
                <el-option v-if="item.title_status == '显示'" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="正文模版" v-if="!this.$route.query.id">
            <el-select v-model="tempId" placeholder="请选择" class="sid" style="width: 260px" @change="changeTemp">
              <el-option v-for="item in templateList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <div class="pr80">
              <Editor v-model="form.content"></Editor>
            </div>
          </el-form-item>

          <el-form-item>
            <div style="margin-top: 10px">
              <el-button @click="resetForm('form')">取消</el-button>
              <el-button type="primary" @click="submitForm('form')" :loading="save_loading_btn">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { articleTemplate } from "@/api/teacherStudio/wechatApi"
import { debounce, throttle } from "@/utils/utils.js";
export default {
  data() {
    return {
      save_loading_btn: false,
      //分类列表
      typeList: [],
      templateList: [],
      tempId: "",
      oldId: "",
      //表单
      form: {
        name: "",
        type: "",
        content: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      optiones: [
        {
          id: 0,
          name: "单选题",
        },
        {
          id: 1,
          name: "多选题",
        },
      ],
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 0, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择分类", trigger: "change" }],
        content: [{ required: true, message: "请填写内容", trigger: "blur" }],
      },
      routeName: "",
    };
  },
  created() {
    this.routeName = this.$route.name
    this._articleTemplate()
    //获取文章分类
    this.getArticleType();
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    async getDetails() {
      const res = await this.$axios.get("/teachingStudioArticle/details", {
        params: {
          id: this.$route.query.id
        }
      });
      this.form = res.data.data
    },
    changeTemp() {
      if (this.form.content) {
        this.$confirm('切换模板会清空所有内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.oldId = this.tempId
          this.templateList.forEach(item => {
            if (item.id == this.tempId) {
              this.form.content = item.cont
            }
          })
        }).catch(() => {
          this.tempId = this.oldId
        });
      } else {
        this.oldId = this.tempId
        this.templateList.forEach(item => {
          if (item.id == this.tempId) {
            this.form.content = item.cont
          }
        })
      }
    },
    //获取文章分类
    getArticleType() {
      let obj = {
        search_name: "",
        page: "",
        limit: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios
        .get("TeachingStudioArticleType/list", {
          params: obj,
        })
        .then((res) => {
          this.typeList = res.data.data.data;
        });
    },
    _articleTemplate() {
      articleTemplate({ limit: 999 }).then(res => {
        this.templateList = res.data.data
      })
    },
    //提交
    submitForm: throttle(function (formName) {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save_loading_btn = true;
          if (this.$route.query.id) {
            this.$axios.put("teachingStudioArticle/edit", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message.success("修改成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1000);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          } else {
            this.$axios.post("teachingStudioArticle/add", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message.success("发布成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1000);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          }
        }
      });
    }),
    //   取消
    resetForm() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.pushBox {
  padding-bottom: 40px;

  .ArrBox {
    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    //盒子
    .BoxItem {
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 6px;
      padding: 30px 50px;

      h4 {
        margin-bottom: 24px;
        margin-left: 48px;
      }
    }
  }
}
</style>